/* AnimatedBackground.css */
.animated-background {
    position: fixed; /* Keeps the background in place */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Places it behind the scrolling content */
    pointer-events: none; /* Ensures it doesn’t block clicks or interactions */
  }
  
  .gradient {
    position: absolute;
    border-radius: 50%; /* Ensures a circular shape */
    filter: blur(30px); /* Stronger blur for a blob effect */
    animation: blobMovement 10s ease-in-out infinite; /* Adds randomness */
  }
  
  .gradient-primary {
    background: conic-gradient(
      from 90deg at 50% 50%,
      #ff8b7e 0deg,
      #e24e6b 160deg,
      #7ed2da 120deg,
      #8bdce0 55deg,
      transparent 360deg
    );
    width: 50vw;
    height: 50vw;
    top: 20%; /* Initial positioning */
    left: 10%;
  }
  
  .gradient-secondary {
    background: conic-gradient(
      from 10deg at 50% 50%,
      #eb7494 0deg,
      #ae77b2 55deg,
      #97b5da 120deg,
      #0099ca 160deg,
      transparent 360deg
    );
    width: 500px;
    height: 700px;
    top: 60%; /* Initial positioning */
    left: 70%;
    transform: translate(-50%, -50%);
  }
  
  /* Randomized blob-like movement */
  @keyframes blobMovement {
    0% {
      transform: translate(0, 0) scale(1);
    }
    25% {
      transform: translate(-30px, 50px) scale(1.1);
    }
    50% {
      transform: translate(50px, -30px) scale(0.9);
    }
    75% {
      transform: translate(-40px, -50px) scale(1.2);
    }
    100% {
      transform: translate(0, 0) scale(1);
    }
  }
  